<template>
  <div>
    <CRow>
      <CCol sm="4">
        <h4 class="text-uppercase">Update Consumer</h4>
      </CCol>
      <CCol sm="8"></CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <CCard class="p-3 pr-4">
          <CRow>
            <CCol>
              <div v-if="serverErrors.length">
                <CAlert v-model="showDismissibleAlert" color="warning" closeButton>
                  <span v-for="err in serverErrors" :key="err">{{ err }}</span>
                </CAlert>
              </div>

              <div class="row" v-if="!accountType">
                <div class="col-md-12 text-center actions">
                  <button
                      type="button"
                      class="btn btn-outline-primary btn-lg"
                      @click="selectAccountType('new')"
                  >
                    {{ selected_customer_id ? "New Consumer account" : "New Consumer" }}
                  </button>
                  <button
                      v-if="!selected_customer_id"
                      type="button"
                      class="btn btn-outline-primary btn-lg"
                      @click="selectAccountType('old')"
                  >
                    Existing Consumer
                  </button>
                </div>
              </div>

              <div class="row" v-if="accountType === 'old'">
                <div class="col-md-9 text-center actions">
                  <input
                      type="search"
                      class="form-control"
                      v-model="search.account_number"
                      placeholder="Search by account number"
                      @keyup.enter="searchCustomer()"
                      required
                  />
                </div>
                <div class="col-md-3">
                  <button
                      type="button"
                      class="btn btn-primary"
                      @click="searchCustomer()"
                  >
                    Search
                  </button>
                </div>
                <div class="col-md-12" v-if="customer.customer_id">
                  <hr/>
                </div>
              </div>

              <validation-observer ref="formValidator">
                <form
                    v-on:submit.prevent="addUpdateConsumer"
                    v-if="
            accountType === 'new' ||
              (accountType === 'old' && customer.customer_id)
          "
                >
                  <div class="row">
                    <div class="col-md-6">
                      <ValidationProvider
                          class="form-group row"
                          name="Account No"
                          vid="account_number"
                          :rules="{ required: true, alpha_num: true, min: 6, max:8 }"
                          v-slot="{ errors }"
                      >
                        <label class="col-md-5 col-form-label text-right">Account No. <span class="required">*</span></label>
                        <CInput
                            class="mb-0 col-md-6 p-0"
                            :addInputClasses="errors.length ? 'is-invalid' : ''"
                            type="text"
                            v-model="customer.account_number"
                            placeholder="Enter Consumer's Account No."
                            required
                        />
                        <span class="text-danger mt-0 pt-0 pl-2 offset-5">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <ValidationProvider
                          class="form-group row"
                          name="Conn. Memo No"
                          vid="memo_no"
                          :rules="{ required: false }"
                          v-slot="{ errors }"
                      >
                        <label class="col-md-5 col-form-label text-right">Conn. Memo No</label>
                        <CInput
                            class="mb-0 col-md-6 p-0"
                            :addInputClasses="errors.length ? 'is-invalid' : ''"
                            type="text"
                            v-model="customer.memo_no"
                            placeholder="Conn. Memo No"
                        />
                        <span class="text-danger mt-0 pt-0 pl-2 offset-5">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <ValidationProvider
                          class="form-group row"
                          vid="first_name"
                          name="Name"
                          :rules="{ required: true,  min: 3 }"
                          v-slot="{ errors }"
                      >
                        <label class="col-md-5 col-form-label text-right">Name <span class="required">*</span></label>
                        <CInput
                            class="mb-0 col-md-6 p-0"
                            type="text"
                            v-model="customer.first_name"
                            placeholder="Enter consumer name"
                            required
                            :addInputClasses="errors.length ? 'is-invalid' : ''"
                        />
                        <span class="text-danger mt-0 pt-0 pl-2 offset-5">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <ValidationProvider
                          class="form-group row"
                          vid="national_id_no"
                          name="National ID No"
                          v-slot="{ errors }"
                      >
                        <label class="col-md-5 col-form-label text-right">National ID No</label>
                        <CInput
                            class="mb-0 col-md-6 p-0"
                            type="number"
                            v-model="customer.national_id_no"
                            placeholder="Enter National ID No"
                            :addInputClasses="errors.length ? 'is-invalid' : ''"
                        />
                        <span class="text-danger mt-0 pt-0 pl-2 offset-5">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <ValidationProvider
                          class="form-group row"
                          vid="tin_no"
                          name="TIN No"
                          v-slot="{ errors }"
                      >
                        <label class="col-md-5 col-form-label text-right">TIN No</label>
                        <CInput
                            class="mb-0 col-md-6 p-0"
                            type="number"
                            v-model="customer.tin_no"
                            placeholder="Enter TIN No"
                            :addInputClasses="errors.length ? 'is-invalid' : ''"
                        />
                        <span class="text-danger mt-0 pt-0 pl-2 offset-5">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <ValidationProvider
                          class="form-group row"
                          name="Husband/Father"
                          vid="guardian"
                          :rules="{ required: false }"
                          v-slot="{ errors }"
                      >
                        <label class="col-md-5 col-form-label text-right">Husband/Father</label>
                        <CInput
                            class="mb-0 col-md-6 p-0"
                            :addInputClasses="errors.length ? 'is-invalid' : ''"
                            type="text"
                            v-model="customer.guardian"
                            placeholder="Husband/Father"
                        />
                        <span class="text-danger mt-0 pt-0 pl-2 offset-5">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <ValidationProvider
                          class="form-group row"
                          name="Address"
                          vid="address"
                          :rules="{ required: false }"
                          v-slot="{ errors }"
                      >
                        <label class="col-md-5 col-form-label text-right">Address</label>
                        <input
                            :class="errors.length ? 'is-invalid' : ''"
                            name="address"
                            class="form-control col-md-6"
                            v-model="customer.address"
                            placeholder="Address 1"
                        />
                        <span class="text-danger mt-0 pt-0 pl-2 offset-5">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <ValidationProvider
                          class="form-group row"
                          name="Address 2"
                          vid="address"
                          :rules="{ required: false }"
                          v-slot="{ errors }"
                      >
                        <label class="col-md-5 col-form-label text-right">Address 2</label>
                        <input
                            :class="errors.length ? 'is-invalid' : ''"
                            name="address"
                            class="form-control col-md-6"
                            v-model="customer.address2"
                            placeholder="Address 2"
                        />
                        <span class="text-danger mt-0 pt-0 pl-2 offset-5">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <ValidationProvider
                          class="form-group row"
                          name="Property Type"
                          vid="property_type"
                          :rules="{ required: false }"
                          v-slot="{ errors }"
                      >
                        <label class="col-md-5 col-form-label text-right">Property Type</label>
                        <select
                            :class="errors.length ? 'is-invalid' : ''"
                            name="property_type"
                            v-model="customer.property_type"
                            class="form-control col-md-6"
                        >
                          <option value="">Select</option>
                          <option v-for="property in constants.property" :value="property">{{ property }}</option>
                        </select>
                        <span class="text-danger mt-0 pt-0 pl-2 offset-5">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <ValidationProvider
                          class="form-group row"
                          name="Ward Number"
                          vid="ward_number"
                          :rules="{ required: false }"
                          v-slot="{ errors }"
                      >
                        <label class="col-md-5 col-form-label text-right">Ward Number</label>
                        <select
                            :class="errors.length ? 'is-invalid' : ''"
                            name="ward_number"
                            v-model="customer.ward_number"
                            class="form-control col-md-6"
                        >
                          <option value="">Select Ward Number</option>
                          <option v-for="wardNumber in wardLists" :value="wardNumber">{{ wardNumber }}</option>
                        </select>
                        <span class="text-danger mt-0 pt-0 pl-2 offset-5">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <ValidationProvider
                          class="form-group row"
                          name="Contact No"
                          vid="mobile_number"
                          :rules="{ required: false, numeric: true, min: 11, digits: 11 }"
                          v-slot="{ errors }"
                      >
                        <label class="col-md-5 col-form-label text-right">Contact No </label>
                        <CInput
                            class="mb-0 col-md-6 p-0"
                            :addInputClasses="errors.length ? 'is-invalid' : ''"
                            type="number"
                            v-model="customer.mobile_number"
                            placeholder="Contact No"
                        />
                        <span class="text-danger mt-0 pt-0 pl-2 offset-5">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <ValidationProvider
                          class="form-group row"
                          name="Email"
                          vid="email"
                          :rules="{ required: false, email: true }"
                          v-slot="{ errors }"
                      >
                        <label class="col-md-5 col-form-label text-right">Email</label>
                        <CInput
                            class="mb-0 col-md-6 p-0"
                            :addInputClasses="errors.length ? 'is-invalid' : ''"
                            type="email"
                            v-model="customer.email"
                            placeholder="Email"
                        />
                        <span class="text-danger mt-0 pt-0 pl-2 offset-5">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <ValidationProvider
                          class="form-group row"
                          name="Size of Conn."
                          vid="connection_size"
                          :rules="{ required: false }"
                          v-slot="{ errors }"
                      >
                        <label class="col-md-5 col-form-label text-right">Size of Conn.</label>
                        <select
                            :disabled="true"
                            :class="errors.length ? 'is-invalid' : ''"
                            name="connection_size"
                            v-model="customer.meter.connection_size"
                            class="form-control col-md-6"
                        >
                          <option value="">Select</option>
                          <option v-for="(meter_size, key) in constants.meter_sizes" :value="key">
                            {{ meter_size }}
                          </option>
                        </select>
                        <span class="text-danger mt-0 pt-0 pl-2 offset-5">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <ValidationProvider
                          class="form-group row"
                          name="Category"
                          vid="category"
                          :rules="{ required: true }"
                          v-slot="{ errors }"
                      >
                        <label class="col-md-5 col-form-label text-right">Category <span class="required">*</span></label>
                        <select
                            :disabled="true"
                            :class="errors.length ? 'is-invalid' : ''"
                            name="category"
                            v-model="customer.category"
                            class="form-control col-md-6"
                            required
                        >
                          <option value="">Select</option>
                          <option v-for="(category, categoryKey) in constants.category" :value="categoryKey">{{ category }}</option>
                        </select>
                        <span class="text-danger mt-0 pt-0 pl-2 offset-5">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <ValidationProvider
                          class="form-group row"
                          name="Connection Fee"
                          vid="connection_fee"
                          :rules="{ required: false}"
                          v-slot="{ errors }"
                      >
                        <label class="col-md-5 col-form-label text-right">Connection Fee</label>
                        <CInput
                            class="mb-0 col-md-6 p-0"
                            :addInputClasses="errors.length ? 'is-invalid' : ''"
                            type="number"
                            v-model="customer.connection_fee"
                            placeholder="Connection Fee"
                        />
                        <span class="text-danger mt-0 pt-0 pl-2 offset-5">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <ValidationProvider
                          class="form-group row"
                          name="Class"
                          vid="class"
                          :rules="{ required: false }"
                          v-slot="{ errors }"
                      >
                        <label class="col-md-5 col-form-label text-right">Class</label>
                        <select
                            :class="errors.length ? 'is-invalid' : ''"
                            name="class"
                            v-model="customer.class"
                            class="form-control col-md-6"
                        >
                          <option value="">Select</option>
                          <option v-for="(class_name, classKey) in constants.class" :value="classKey">{{ class_name }}</option>
                        </select>
                        <span class="text-danger mt-0 pt-0 pl-2 offset-5">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <ValidationProvider
                          class="form-group row"
                          name="Connection date"
                          vid="connection_date"
                          :rules="{ required: false}"
                          v-slot="{ errors }"
                      >
                        <label class="col-md-5 col-form-label text-right">Connection date</label>
                        <el-date-picker
                            class="mb-0 col-md-6 p-0"
                            v-model="customer.connection_date"
                            type="date"
                            :addInputClasses="errors.length ? 'is-invalid' : ''"
                            placeholder="Connection date"
                            :format="$datePickerFormat"
                            value-format="yyyy-MM-dd"
                        />
                        <span class="text-danger mt-0 pt-0 pl-2 offset-5">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <ValidationProvider
                          class="form-group row"
                          name="Meter install date"
                          vid="installing_date"
                          :rules="{ required: false}"
                          v-slot="{ errors }"
                      >
                        <label class="col-md-5 col-form-label text-right">Meter install date</label>
                        <el-date-picker
                            :disabled="hasNotMeter"
                            class="mb-0 col-md-6 p-0"
                            v-model="customer.meter.installing_date"
                            type="date"
                            :addInputClasses="errors.length ? 'is-invalid' : ''"
                            placeholder="Meter install date"
                            :format="$datePickerFormat"
                            value-format="yyyy-MM-dd"
                        />
                        <span class="text-danger mt-0 pt-0 pl-2 offset-5">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <ValidationProvider
                          class="form-group row"
                          name="Meter No"
                          vid="meter_number"
                          :rules="{ required: false}"
                          v-slot="{ errors }"
                      >
                        <label class="col-md-5 col-form-label text-right">Meter No</label>
                        <CInput
                            :readonly="hasNotMeter"
                            class="mb-0 col-md-6 p-0"
                            :addInputClasses="errors.length ? 'is-invalid' : ''"
                            type="text"
                            v-model="customer.meter.meter_number"
                            placeholder="Meter No"
                        />
                        <span class="text-danger mt-0 pt-0 pl-2 offset-5">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-6">
                      <ValidationProvider
                          class="form-group row"
                          name="Initial reading"
                          vid="initial_reading"
                          :rules="{ required: false}"
                          v-slot="{ errors }"
                      >
                        <label class="col-md-5 col-form-label text-right">Initial reading</label>
                        <CInput
                            :readonly="hasNotMeter"
                            class="mb-0 col-md-6 p-0"
                            :addInputClasses="errors.length ? 'is-invalid' : ''"
                            type="text"
                            v-model="customer.meter.initial_reading"
                            placeholder="Initial reading"
                        />
                        <span class="text-danger mt-0 pt-0 pl-2 offset-5">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <ValidationProvider
                          class="form-group row"
                          name="Reading date"
                          vid="reading_date"
                          :rules="{ required: false}"
                          v-slot="{ errors }"
                      >
                        <label class="col-md-5 col-form-label text-right">Reading date</label>
                        <el-date-picker
                            :disabled="true"
                            class="mb-0 col-md-6 p-0"
                            v-model="customer.meter.reading_date"
                            type="date"
                            :addInputClasses="errors.length ? 'is-invalid' : ''"
                            placeholder="Reading date"
                            :format="$datePickerFormat"
                            value-format="yyyy-MM-dd"
                        />
                        <span class="text-danger mt-0 pt-0 pl-2 offset-5">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <ValidationProvider
                          class="form-group row"
                          name="Meter Type"
                          vid="meter_type"
                          :rules="{ required: false }"
                          v-slot="{ errors }"
                      >
                        <label class="col-md-5 col-form-label text-right">Meter Type</label>
                        <select
                            :disabled="true"
                            :class="errors.length ? 'is-invalid' : ''"
                            name="class"
                            v-model="customer.meter.meter_type"
                            class="form-control col-md-6"
                        >
                          <option value="">Select</option>
                          <option v-for="(meter_type, typeKey) in constants.meter_type" :value="typeKey">
                            {{ meter_type }}
                          </option>
                        </select>
                        <span class="text-danger mt-0 pt-0 pl-2 offset-5">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <ValidationProvider
                          class="form-group row"
                          name="Meter Status"
                          vid="meter_status"
                          :rules="{ required: false }"
                          v-slot="{ errors }"
                      >
                        <label class="col-md-5 col-form-label text-right">Meter Status</label>
                        <select
                            :disabled="true"
                            @change="changeMeterStatus()"
                            :class="errors.length ? 'is-invalid' : ''"
                            name="class"
                            v-model="customer.meter.meter_status"
                            class="form-control col-md-6"
                        >
                          <option value="">Select</option>
                          <option v-for="(meter_status, statusKey) in constants.meter_status" :value="statusKey">
                            {{ meter_status }}
                          </option>
                        </select>
                        <span class="text-danger mt-0 pt-0 pl-2 offset-5">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <ValidationProvider
                          class="form-group row"
                          name="Meter Digit Width"
                          vid="meter_digit_width"
                          :rules="{ required: false}"
                          v-slot="{ errors }"
                      >
                        <label class="col-md-5 col-form-label text-right">Meter Digit Width</label>
                        <CInput
                            :readonly="hasNotMeter"
                            class="mb-0 col-md-6 p-0"
                            :addInputClasses="errors.length ? 'is-invalid' : ''"
                            type="text"
                            v-model="customer.meter.meter_digit_width"
                            placeholder="Meter Digit Width"
                        />
                        <span class="text-danger mt-0 pt-0 pl-2 offset-5">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <ValidationProvider
                          class="form-group row"
                          name="Non Domestic %"
                          vid="non_domestic"
                          :rules="{ required: false}"
                          v-slot="{ errors }"
                      >
                        <label class="col-md-5 col-form-label text-right">Non Domestic %</label>
                        <CInput
                            readonly=""
                            class="mb-0 col-md-6 p-0"
                            :addInputClasses="errors.length ? 'is-invalid' : ''"
                            type="text"
                            v-model="customer.non_domestic"
                            placeholder="Non Domestic %"
                        />
                        <span class="text-danger mt-0 pt-0 pl-2 offset-5">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <ValidationProvider
                          class="form-group row"
                          name="Avg Consumption"
                          vid="avg_consumption"
                          :rules="{ required: false}"
                          v-slot="{ errors }"
                      >
                        <label class="col-md-5 col-form-label text-right">Avg Consumption</label>
                        <CInput
                            readonly=""
                            class="mb-0 col-md-6 p-0"
                            :addInputClasses="errors.length ? 'is-invalid' : ''"
                            type="text"
                            v-model="customer.avg_consumption"
                            placeholder="Avg Consumption"
                        />
                        <span class="text-danger mt-0 pt-0 pl-2 offset-5">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <ValidationProvider
                          class="form-group row"
                          name="Meter Inspection Code"
                          vid="meter_inspector_id"
                          :rules="{ required: true }"
                          v-slot="{ errors }"
                      >
                        <label class="col-md-5 col-form-label text-right">Meter Inspection Code <span class="required">*</span></label>
                        <select
                            :class="errors.length ? 'is-invalid' : ''"
                            name="class"
                            v-model="customer.meter_inspector_id"
                            class="form-control col-md-6"
                            required
                        >
                          <option value="">Select</option>
                          <option v-for="mi_code in micodeLists" :value="mi_code.id">{{ mi_code.mi_code }}</option>
                        </select>
                        <span class="text-danger mt-0 pt-0 pl-2 offset-5">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <ValidationProvider
                          class="form-group row"
                          name="Photo ID"
                          vid="photo"
                          :rules="{ required: false}"
                          v-slot="{ errors }"
                      >
                        <label class="col-md-5 col-form-label text-right">Photo ID</label>
                        <CInput
                            class="mb-0 col-md-6 p-0"
                            :addInputClasses="errors.length ? 'is-invalid' : ''"
                            type="file"
                            v-model="customer.photo"
                            placeholder="Photo ID"
                        />
                        <span class="text-danger mt-0 pt-0 pl-2 offset-5">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <ValidationProvider
                          class="form-group row"
                          name="Family"
                          vid="family"
                          :rules="{ required: false}"
                          v-slot="{ errors }"
                      >
                        <label class="col-md-5 col-form-label text-right">Family</label>
                        <CInput
                            class="mb-0 col-md-6 p-0"
                            :addInputClasses="errors.length ? 'is-invalid' : ''"
                            type="text"
                            v-model="customer.family"
                            placeholder="Family"
                        />
                        <span class="text-danger mt-0 pt-0 pl-2 offset-5">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <ValidationProvider
                          class="form-group row"
                          name="Freedom Fighter"
                          vid="freedom_fighter"
                          :rules="{ required: false}"
                          v-slot="{ errors }"
                      >
                        <label class="col-md-5 col-form-label text-right">Freedom Fighter</label>
                        <input
                            :disabled="!checkUsrPermissions(['Consumer_Freedom_Fighter'])"
                            class="mb-0"
                            :class="errors.length ? 'is-invalid' : ''"
                            type="checkbox"
                            v-model="customer.freedom_fighter"
                        />
                        <span class="text-danger mt-0 pt-0 pl-2 offset-5">{{ errors[0] }}</span>
                      </ValidationProvider>

                      <ValidationProvider
                          class="form-group row"
                          name="Exemption VAT"
                          vid="exemption_vat"
                          :rules="{ required: false}"
                          v-slot="{ errors }"
                      >
                        <label class="col-md-5 col-form-label text-right">Exemption VAT</label>
                        <input
                            :disabled="!checkUsrPermissions(['Consumer_Exemption_VAT'])"
                            class="mb-0"
                            :class="errors.length ? 'is-invalid' : ''"
                            type="checkbox"
                            v-model="customer.exemption_vat"
                        />
                        <span class="text-danger mt-0 pt-0 pl-2 offset-5">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <ValidationProvider
                          class="form-groupx row"
                          name="Exemption Surch"
                          vid="exemption_surch"
                          :rules="{ required: false}"
                          v-slot="{ errors }"
                      >
                        <label class="col-md-5 col-form-label text-right">Exemption Surch</label>
                        <input
                            :disabled="!checkUsrPermissions(['Consumer_Exemption_Surch'])"
                            class="mb-0"
                            :class="errors.length ? 'is-invalid' : ''"
                            type="checkbox"
                            v-model="customer.exemption_surch"
                        />
                        <span class="text-danger mt-0 pt-0 pl-2 offset-5">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <ValidationProvider
                          class="form-group row"
                          name="Ministry Code"
                          vid="ministry_code"
                          :rules="{ required: false}"
                          v-slot="{ errors }"
                      >
                        <label class="col-md-5 col-form-label text-right">Ministry Code</label>
                        <CInput
                            class="mb-0 col-md-6 p-0"
                            :addInputClasses="errors.length ? 'is-invalid' : ''"
                            type="text"
                            v-model="customer.ministry_code"
                            placeholder="Ministry Code"
                        />
                        <span class="text-danger mt-0 pt-0 pl-2 offset-5">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <ValidationProvider
                          class="form-group row"
                          name="Account Group"
                          vid="account_group"
                          :rules="{ required: false }"
                          v-slot="{ errors }"
                      >
                        <label class="col-md-5 col-form-label text-right">Account Group</label>
                        <select
                            :class="errors.length ? 'is-invalid' : ''"
                            name="class"
                            v-model="customer.account_group"
                            class="form-control col-md-6"
                        >
                          <option value="">Select</option>
                          <option v-for="account_group in constants.account_group" :value="account_group">
                            {{ account_group }}
                          </option>
                        </select>
                        <span class="text-danger mt-0 pt-0 pl-2 offset-5">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </div>

                  <button v-if="accountType" type="submit" class="pull-right btn btn-primary btn-lg">
                    {{ $route.params.id ? 'Update' : 'Save' }}
                  </button>

                  <button @click="resetForm" type="button" class="btn btn-warning mr-4">
                    Refresh
                  </button>

                  <router-link :to="{name:'Consumers'}" class="btn btn-secondary">
                    Back
                  </router-link>
                </form>
              </validation-observer>
            </CCol>
          </CRow>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Multiselect from "vue-multiselect";

export default {
  name: "ConsumerCreate",
  components: {Multiselect},
  props: {
    selected_customer_id: {
      default: null
    },
  },
  data: () => {
    return {
      breadcrumbs: [
        {text: "Home", path: "/dashboard", isActive: false},
        {text: "Consumers", path: "/consumers", isActive: false},
        {text: "Update Consumer", path: "", isActive: true}
      ],
      accountType: "new",
      search: {
        account_number: "",
      },
      customer: {
        id: '',
        generate_account_no: false,
        account_number: '',
        memo_no: '',
        first_name: "",
        guardian: "",
        address: "",
        property_type: "",
        ward_number: "",
        mobile_number: "",
        email: "",
        category: "",
        connection_fee: "",
        class: "",
        connection_date: "",
        meter: {
          connection_size: "",
          installing_date: "",
          meter_number: "",
          initial_reading: "",
          reading_date: "",
          meter_type: "",
          meter_status: "",
          meter_digit_width: "",
        },
        non_domestic: "",
        avg_consumption: "",
        meter_inspector_id: "",
        photo: "",
        family: "",
        freedom_fighter: false,
        exemption_vat: false,
        exemption_surch: false,
        ministry_code: "",
        account_group: "",
        national_id_no: "",
        tin_no: "",
      },
      serverErrors: [],
      showDismissibleAlert: false,
      isLoading: false,
      hasNotMeter: false
    };
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
  computed: {
    ...mapGetters("Wards", ["wardLists"]),
    ...mapGetters("Constant", ["constants"]),
    ...mapGetters("MiCode", ["micodeLists"]),
    wardNumbers() {
      return Array.from({length: 30}, (_, index) => index + 1);
    }
  },
  mounted() {
    this.$store.dispatch("Constant/getConstants", 'category,class,property,account_group,meter_sizes,meter_type,meter_status');
    this.$store.dispatch("MiCode/getMiCodeLists");
    this.$store.dispatch("Wards/getWardLists");
    this.customer.customer_id = this.selected_customer_id

    if (this.$route.params.id) {
      this.getConsumer(this.$route.params.id)
    }
  },
  methods: {
    changeMeterStatus() {
      if (this.customer.meter.meter_status != 2) {
        return this.customer.avg_consumption = ""
      }
      this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/admin/last90-days-avg-consumption/${this.customer.meter.id}`).then(({data}) => {
            this.customer.avg_consumption = data.averageDifference
            this.$store.dispatch("Settings/loading", false);
          }, (error) => {
            this.$toastr.e("Consumer not found.", "Failed!");
            this.$store.dispatch("Settings/loading", false);
            this.resetForm();
          }
      );
    },
    getConsumer(id) {
      this.$store.dispatch("Settings/loading", true);
      this.axios.get(`${process.env.VUE_APP_API_BASE_URL}/admin/consumers/${id}`).then((res) => {
            this.customer = res.data.data;
            if (!this.customer.meter){
              this.hasNotMeter = true;
              this.customer.meter = {
                installing_date: "",
                meter_number: "",
                initial_reading: "",
                reading_date: "",
                meter_type: "",
                meter_status: "",
                meter_digit_width: "",
              }
            }
            this.$store.dispatch("Settings/loading", false);
          }, (error) => {
            this.$toastr.e("Consumer not found.", "Failed!");
            this.$store.dispatch("Settings/loading", false);
            this.resetForm();
          }
      );
    },
    selectAccountType(type) {
      this.accountType = type;
    },
    searchCustomer() {
      this.$store.dispatch("Settings/loading", true);
      return this.axios
          .post(
              `${process.env.VUE_APP_API_BASE_URL}/admin/customer/search-by-account-no`,
              this.search
          )
          .then(
              (res) => {
                this.setCustomerData(res.data.data);
                this.$store.dispatch("Settings/loading", false);
              },
              (error) => {
                this.$toastr.e("Account number not found.", "Failed!");
                this.$store.dispatch("Settings/loading", false);
                this.resetForm();
              }
          );
    },
    resetForm() {
      this.customer = {
        id: '',
        generate_account_no: false,
        account_number: '',
        memo_no: '',
        first_name: "",
        guardian: "",
        address: "",
        address2: "",
        property_type: "",
        ward_number: "",
        mobile_number: "",
        email: "",
        connection_size: "",
        category: "",
        connection_fee: "",
        class: "",
        connection_date: "",
        meter: {
          installing_date: "",
          meter_number: "",
          initial_reading: "",
          reading_date: "",
          meter_type: "",
          meter_status: "",
          meter_digit_width: "",
        },
        non_domestic: "",
        avg_consumption: "",
        meter_inspector_id: "",
        photo: "",
        family: "",
        freedom_fighter: false,
        exemption_vat: false,
        exemption_surch: false,
        ministry_code: "",
        account_group: "",
        national_id_no: "",
        tin_no: "",
      };
    },
    setCustomerData(data) {
      let fields = [
        "salutation",
        "first_name",
        "last_name",
        "email",
        "mobile_number",
        "customer_id",
        "category"
      ];
      for (let key in fields) {
        this.customer[fields[key]] = data[fields[key]];
      }
    },
    addUpdateConsumer() {
      try {
        this.$store.dispatch("Settings/loading", true);
        let status = {
          title: 'Successfully Added customer account',
          status2: 'Account Created',
        }
        if (this.$route.params.id) {
          status = {
            title: 'Successfully Updated customer account',
            status2: 'Account Updated',
          }
        }
        this.$store.dispatch("Consumer/update", this.customer).then(() => {
          this.$store.dispatch("Settings/loading", false);
          this.$toastr.s(status.title,
              status.status2
          );
          // this.$store.dispatch("Consumer/getConsumers", {
          //   mobile_number: "",
          //   name: "",
          //   account_number: "",
          //   ward_number: "",
          //   currentPage: 1,
          // });
          this.resetForm();
          this.$router.push({name: 'Consumers'})
        })
            .catch((err) => {
              this.$store.dispatch("Settings/loading", false);
              this.serverErrors = [];
              if (err.response.data.message) {
                this.serverErrors.push(err.response.data.message);
              }

              let errors = err.response.data.errors;
              for (let field of Object.keys(errors)) {
                this.$toastr.e("error", errors[field][0], "Error!");
              }
            });
      } catch (err) {
        this.isLoading = false;
      }
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.actions {
  button {
    margin: 10px;
    padding: 15px 20px;
  }
}
</style>
<style>
html:not([dir="rtl"]) .was-validated .form-control:invalid, html:not([dir="rtl"]) .form-control.is-invalid {
  padding-right: 11px !important;
}
</style>
